@tailwind base;
@tailwind apply;
@tailwind components;

/* --- From old global styles.less || normalization --- */
body {
  margin: 0;
  line-height: 1.5715;
  color: rgba(26, 32, 44, 0.85);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

table {
  border-collapse: collapse;
}
/* -------------- */

/* Custom styles */
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis-multiline {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ap-input {
  border-width: 0.125rem;
  border-radius: 0.375rem;
}

.ap-dropdown-menu.ap-with-places {
  position: static !important;
}

.ant-modal-body {
  margin-top: 24px;
}

.ant-btn {
  line-height: 1.25;
}

.ant-input-search-button {
  height: calc(32px + 0.25rem - 0.25px) !important;
}

.ant-input-search-button.ant-btn-sm {
  height: calc(24px + 0.25rem - 0.25px) !important;
}

.ant-input-search-button.ant-btn-lg {
  height: calc(36px + 0.25rem - 0.25px) !important;
}

.ant-btn > .ant-typography > .ant-typography-copy {
  margin: 0 !important;
}

.course-instance-limit-form-item > div {
  max-width: 100% !important;
}

.preferred-subsidiary-selector > .ant-select-selector {
  display: flex;
  align-items: center;
}

.preferred-subsidiary-selector .ant-select-selection-item {
  color: white !important;
}

tr.hover-shadow:hover {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);
}

.blueButton {
  font-size: 24px;
  color: #2a5c96;
  cursor: pointer;
}

.transparentRadioGroup > label {
  border-radius: 0px !important;
  border-style: none !important;
  background: none !important;
}

.transparentRadioGroup > .ant-radio-button-wrapper-checked {
  text-decoration: underline !important;
}

.transparentRadioGroup
  > .ant-radio-button-wrapper-checked
  > .ant-radio-button-checked {
  background: solid !important;
  background-color: #2c5282 !important;
  border-radius: 0.5rem !important;
}

.transparentRadioGroup > label::before {
  display: none !important;
}

.hideRequiredWarning .ant-form-item-explain-error {
  display: none !important;
}

.actionPopover
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content {
  padding: 0px 0px !important;
}

.informationCardText > div {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.information-container:hover > .action-button > .action-button-background {
  background: solid !important;
  background-color: #f8fbfd;
}

.action-button-background:hover > .action-button-icon {
  filter: brightness(0) invert(1);
}

.nav-bar-menu-item {
  @apply mx-2 px-3 py-2 rounded-md text-base font-normal text-white transition duration-200 ease-in-out cursor-pointer;
}

.nav-bar-menu-item:focus {
  @apply outline-none text-white bg-primary-700;
}

.nav-bar-menu-item:hover {
  @apply text-white bg-primary-700;
  @extend nav-bar-menu-item;
}

.preferred-subsidiary-selector > .ant-select-arrow {
  display: flex;
  align-items: center;
}

.gravatar-small {
  @apply h-8 w-8;
}

.gravatar-medium {
  @apply h-10 w-10;
}

.gravatar-large {
  @apply h-16 w-16;
}

.exclamation-badge {
  @apply absolute bottom-0 right-0 inline-block border-2 rounded-full text-white bg-warningYellow;
}

.exclamation-badge.small-badge {
  @apply h-3 w-3 text-2xs pt-px;
}

.exclamation-badge.medium-badge {
  @apply h-4 w-4 text-xs pt-2px;
}

.exclamation-badge.large-badge {
  @apply h-6 w-6 text-base pt-1;
}

.internal-badge {
  @apply absolute bottom-0 right-0 inline-block bg-orange-600 border-2 rounded-full text-white;
}

.internal-badge.small-badge {
  @apply h-3 w-3 text-3xs pt-px;
}

.internal-badge.medium-badge {
  @apply h-4 w-4 text-2xs pt-3px;
}

.internal-badge.large-badge {
  @apply h-6 w-6 text-base pt-3px;
}

.instructor-swap-card {
  grid-template-columns: 0.75fr 2.5fr 3fr 3fr 2fr 2fr;
}

.course-instance-status-blip {
  width: 0.6rem;
  min-width: 0.6rem;
  height: 0.6rem;
  border-radius: 9999px;
}

.insights-statistics-graph-card {
  @apply rounded-xl flex flex-col shadow-md z-10 border-solid border-gray-300 border p-4 cursor-pointer transition-shadow duration-150 ease-in bg-white h-40;
}

.insights-statistics-graph-card:hover {
  @apply shadow-lg;
}

.insights-statistics-graph-card:hover .insights-statistics-graph-card-expand {
  color: #4a5568 !important;
}

.course-instance-insight-card {
  grid-template-columns: 2fr 1.75fr 2.5fr !important;
}

.modal-subsidiary-selector > .ant-select-selector {
  height: 34px !important;
}

.insights-course-chart-card {
  @apply relative py-6 rounded-lg self-center w-full bg-offwhite border-2 border-offwhiteDark border-solid cursor-pointer transition-shadow duration-150 ease-in;
}

.insights-course-chart-card-expand {
  @apply absolute text-lg text-gray-400 transition-colors duration-150 ease-in;
  top: 16px;
  right: 16px;
}

.insights-course-chart-card:hover .insights-course-chart-card-expand {
  color: #4a5568 !important;
}

.course-chart-table .ant-table-cell {
  background: none;
  pointer-events: none;
}

.course-chart-table .ant-table-cell::before {
  background: none !important;
}

@tailwind utilities;
